export const theme = {
  font: {
    banner: "FormaDJRBanner",
    bannerMedium: "FormaDJRBanner",
    bold: "FormaDJRText",
    text: "FormaDJRText",
  },
  fontWeight: {
    hairline: "100",
    thin: "200",
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
    black: "900",
  },
  fontSize: {
    xs: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
    "8xl": "6rem",
    header: "8rem",
    cnnlite: "6.5rem",
    cnnvw: "14vw",
    p: "2rem",
    pLarge: "9rem",
    h1: "27.5rem",
    h2: "26rem",
    h3: "12.5rem",
    h4: "4.8rem",
    h5: "4.5rem",
  },
  letterSpacing: {
    tightest: "-0.10em",
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  lineHeight: {
    tiny: "0.75",
    none: "1",
    tight: "1.25",
    snug: "1.375",
    normal: "1.5",
    relaxed: "1.625",
    loose: "2",
    h1: "22rem",
  },
  color: {
    primaryDark: "#0D0C1D",
    primaryLight: "#EFFFFA",
    primaryHover: "#343078",
    black: "#000000",
    grey: "#3A3A3A",
    mediumgrey: "7e7b79",
    lightgrey: "#E1E1E1",
    offWhite: "#fdf6f2",
    white: "#ffffff",
    red: "#FF0000",
    blue: "#017ad9",
  },
  spacing: {
    px: "1px",
    "0": "0",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "8": "2rem",
    "10": "2.5rem",
    "12": "3rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "32": "8rem",
    "40": "10rem",
    "48": "12rem",
    "56": "14rem",
    "64": "16rem",
  },
  maxWidth: "1800px",
  bs: "0 12px 24px 0 rgba(0, 0, 0, 0.09)",
  headerHeight: "10rem",
  footerHeight: "5rem",
  mobileWidth: 1280,
  mobile: "828px",
  fluidTypographyRatio: 0.7,
}
